#root {
  z-index: 2147483647;
  font-family: "Bai Jamjuree", sans-serif;
  --main-dark-pink: #424d6d;
  --main-light-pink: #eb96bd;
  --trade-header-height: 90px;
  @media screen and (max-width: 576px) {
    --trade-header-height: 80px;
  }
}
#walletconnect-wrapper {
  #walletconnect-qrcode-modal {
    .walletconnect-modal__base {
      .walletconnect-modal__header {
        top: 10px;
        padding: 0px 10px;
      }
    }
  }
}
html {
  scroll-behavior: smooth;
  --antd-wave-shadow-color: white;
}
iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  pointer-events: none;
}
body {
  margin: 0;
  font-family: "Bai Jamjuree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-ipad {
  padding-right: 0px;
}

::-webkit-scrollbar {
  width: 00px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #5e72e4;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: "BaiJamjuree";
  src: local("BaiJamjuree"),
    url("./assets/Bai_Jamjuree/BaiJamjuree-Bold.ttf") format ("truetype");
  font-weight: bold;
}

@mixin magic-border($width, $color, $duration, $direction) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: calc(100% + #{$width * 2});
    height: calc(100% + #{$width * 2});
    top: calc(#{$width}/ -1);
    left: calc(#{$width}/ -1);
    background: linear-gradient(to right, $color 0%, $color 100%),
      linear-gradient(to top, $color 50%, transparent 50%),
      linear-gradient(to top, $color 50%, transparent 50%),
      linear-gradient(to right, $color 0%, $color 100%),
      linear-gradient(to left, $color 0%, $color 100%);
    background-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
    background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
    background-repeat: no-repeat, no-repeat;
    transition: transform $duration ease-in-out,
      background-position $duration ease-in-out,
      background-size $duration ease-in-out;
    transform: scaleX(0) rotate(180deg * $direction);
    transition-delay: $duration * 2, $duration, 0s;
  }
  &:hover {
    &:before {
      background-size: 200% $width, $width 400%, $width 400%, 55% $width,
        55% $width;
      background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
      transform: scaleX(1) rotate(180deg * $direction);
      transition-delay: 0s, $duration, $duration * 2;
    }
  }
}

.border-animate {
  font-size: 14px;
  padding: 5px;
  @include magic-border(2px, #999, 0.3s, 0);
}

.member-info-modal {
  .ant-modal-content {
    background: red;
    border-radius: 12px;
    padding: 2px;
    background: linear-gradient(
      245.16deg,
      #a31fa9 -30.47%,
      #8b17a0 -20.79%,
      #740f98 -8.81%,
      #640a92 4.22%,
      #5b078f 18.93%,
      #58068e 38.88%,
      #4d22a3 60.32%,
      #3168da 106.29%,
      #2c75e4 114.46%
    );
  }
  .ant-modal-body {
    padding: 24px;
    padding-top: 48px;
    border-radius: 12px;
    background: linear-gradient(
      116.2deg,
      #71369f -90.02%,
      #3a1c66 -18.51%,
      #230537 41.78%
    );
  }
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper::selection {
  border: none;
}

.model-text {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.text-tag {
  font-size: 16px;
}

.global-button-1 {
  font-weight: 600;
  border: none;
  border-radius: 10px;
  background: radial-gradient(
    96.92% 1534.99% at 95.38% 91.84%,
    #565197 7.32%,
    #ae5297 100%
  );
}
.input-1 {
  background: #1a242f;
  border: none;
  color: white;
  font-size: 14px;
  line-height: 1.5715;
}

.input-2 {
  // background: linear-gradient(146.05deg, #1cad98 20.12%, #59d2d0 80.17%);
  border: none;
  color: white;
  font-size: 14px;
  line-height: 1.5715;
  border-radius: 20px;
  background-color: transparent !important;
  font-weight: 700;
}
.text-color-1 {
  font-family: "Bai Jamjuree";
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #00e894;
}
.button-linear-1 {
  background: linear-gradient(90deg, #00b2ff -3.46%, #00ffc2 97.8%);
  box-shadow: inset 2px 2px 6px rgba(255, 255, 255, 0.25);
  border-radius: 70px;
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}
.d-flex {
  display: flex;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.gap-10 {
  gap: 10px;
}

.gap-50 {
  gap: 50px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-initial {
  justify-content: initial;
}
.justify-content-inherit {
  justify-content: inherit;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-italic {
  font-style: italic;
}

.overflow-scroll-phong {
  overflow: scroll;
  height: 400px;
}
.overflow-scroll-phong::-webkit-scrollbar {
  display: none;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 12px;
}

.p-4 {
  padding: 16px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-3 {
  padding-right: 12px;
}

.pr-4 {
  padding-right: 16px;
}

.m-2 {
  margin: 8px;
}

.m-3 {
  margin: 12px;
}

.m-4 {
  margin: 16px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}
.mt-7 {
  margin-top: 28px;
}

.mt-10 {
  margin-top: 40px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}
.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 16px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.bg-white {
  background-color: white;
}

.bg-trans {
  background-color: transparent;
}

.bg-dark {
  background-color: black;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-third {
  width: 33%;
}

.w-150 {
  width: 150px;
}

.text-red {
  color: red;
}

.block {
  display: block;
}

.-mt-2 {
  margin-top: -12px;
}

.text-sm {
  font-size: 14px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ant-input-number-input::placeholder {
  font-style: italic;
}
.ant-input-number-input:focus::placeholder {
  color: transparent;
}

.input-recipient::placeholder {
  font-style: italic;
}

.input-recipient:focus::placeholder {
  color: transparent;
}

.ant-input:focus {
  border-color: none;
  box-shadow: none;
}

.ant-input-search-button {
  margin-left: 1px;
}
.ant-input-search-button {
  margin-left: 1px;
}

.text-white {
  color: white;
}

.search-table .ant-input::placeholder {
  font-style: italic;
}

.search-table .ant-input:focus::placeholder {
  color: transparent;
}
.search-table .ant-input {
  border: none;
}
.search-input {
  width: 100%;
  .ant-input {
    border-top-left-radius: 100px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    height: 38px;
  }
  .ant-input-group-addon {
    height: 38px;
    border-radius: 100px;
    button {
      border: none;
      height: 100%;
      border-top-right-radius: 100px !important;
      border-bottom-right-radius: 100px !important;
    }
    .ant-btn .anticon {
      background: radial-gradient(
        96.92% 1534.99% at 95.38% 91.84%,
        #915fcd 7.32%,
        #ae5297 100%
      );
      align-items: center;
      margin-right: 3px;
      height: 74%;
      width: 90%;
      justify-content: center;
      border-radius: 100%;
      color: white;
    }
  }
}
.active-btn {
  .ant-btn {
    border: none;
    background-color: white !important;
    svg {
      path {
        stroke: #9b5fcc !important;
      }
    }
    span {
      font-weight: 700;
      color: #9b5fcc !important;
    }
  }
}

.ant-select-selection-item {
  margin-top: 1px;
  margin-left: 3px;
}

.custom-antd-icon-style {
  font-size: 150% !important;
}

.inline-text-w-2 {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.inline-text-w-3 {
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.p-b {
  padding-bottom: 3 0px !important;
}

.fill-black {
  * {
    fill: #1c0c31;
  }
}
.ant-select-dropdown {
  border-radius: 20px;
}

.modalWrapper,
.success-modal {
  .ant-modal-header {
    border: none;
  }
  .ant-modal-body {
    padding: 10px 24px;
  }
  .ant-modal-footer {
    border: none;
  }
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
  }
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.hover__high-light {
  cursor: pointer;
  :hover {
    filter: brightness(1.5);
  }
}
.ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
}

.float-right {
  float: right;
}
.cursor-pointer {
  cursor: pointer;
}
@import "@layouts/trade/header/AccountPanel.scss";

@import "@layouts/trade/header/DrawerHeader.scss";

.btn-swap-token {
  .token-swap-btn {
    img {
      width: 24px;
    }
  }
}
.wrapper-confirm-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-bottom: 32px;
    }
  }
  .ant-btn {
    font-weight: 600;
    border-radius: 20px;
    // width: 100px;
    height: 40px;
    }

    .ant-btn-default {
      background-color: #ffffff;
    }

    .ant-btn-primary {
      background: radial-gradient(96.92% 1534.99% at 95.38% 91.84%,
          #565197 7.32%,
          #ae5297 100%);

      &,
      &:active,
      &:focus {
        border: none;
      }
    }
  .ant-modal-confirm-body {
    .anticon {
      // display: none;
    }
    .ant-modal-confirm-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      // color: #2b2b2b;
    }
    .ant-modal-confirm-content {
      margin-left: 0px !important;
    }
  }
}
.ant-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: 10px;
      * {
        cursor: pointer;
      }
    }
  }
}
.ant-modal-header {
  .ant-modal-title {
    font-family: "Bai Jamjuree";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }
}

.button-footer-model {
  background: radial-gradient(
    96.92% 1534.99% at 95.38% 91.84%,
    #915fcd 7.32%,
    #ae5297 100%
  );
  height: 45px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: white;
}

.button-footer-model:hover {
  background: radial-gradient(
    96.92% 1534.99% at 1000% 1000%,
    #915fcd 7.32%,
    #ae5297 100%
  );

  color: white;
}

.btn-trans {
  height: 45px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.border-none {
  border: none;
}
