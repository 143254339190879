.ivi-menu-dropdown__wrapper {
  //   height: 240px;
  width: 360px;
  background-color: white;
  border-radius: 10px;
  padding: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .ivi-menu-dropdown {
    .dropdown-menu__header {
      //   position: sticky;
      & > div {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #2b2b2b;
        span {
          color: #6e5ac3;
        }
      }
      .mark-all-read {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #9b5fcc;

        &:hover {
          filter: brightness(1.5);
        }
      }
    }
    .dropdown-menu__body {
      height: 240px;
      overflow-y: scroll;
      width: 100%;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 5px;
        width: 8px;
      }

      .notification-item {
        padding: 5px 10px;
        margin-bottom: 4px;
        border-bottom: 1px solid rgba(110, 110, 110, 0.2);
        display: flex;
        &:hover {
          .hover-display {
            opacity: 1;
          }
          background: #dad0e6;
        }
        .hover-display {
          // width: 50px;
          // width: 30px;
          // display: none;
          opacity: 0;
        }
        .bell-icon {
          background-color: rgba(110, 90, 195, 0.2);
          border-radius: 5px;
          width: 24px;
          height: 24px;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .notification-item__body {
          padding-left: 8px;
          h3 {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #2b2b2b;
            margin-bottom: 4px;
            div {
              margin-left: 4px;
            }
            &.unread-noti {
              color: #6e5ac3;
            }
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #6e6e6e;
            margin-bottom: 8px;
          }
        }
      }

      .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        p:first-child {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #2b2b2b;
          margin-bottom: 160px;
        }
        p:last-child {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #9b5fcc;
        }
      }
    }
  }
}
